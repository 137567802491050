header {
  .container {
    @media (max-width: $screen-sm-max) {
      border-bottom: 1px solid $szurke;
    }

    .navbar {
      background-color: #fff;
      border: none;
      margin-bottom: 0;

      .navbar-header {
        @media (max-width: $screen-sm-max) {
          margin-bottom: 10px;
          button{
            margin-top: 20px;
          }
        }
        .navbar-brand {
          padding-left: 0;
          > a {
            > img {
              max-width: 190px;
            }
          }
        }
      }

      .navbar-collapse {
        @media (max-width: $screen-sm-max) {
          text-align: center;
        }
      }
      .nyelvvalaszto {
        display: inline-block;
        list-style: none;
        z-index: 2000;
        margin: 0;
        text-align: center;
        padding: 0;
        @media (min-width: $screen-md-min) {
          position: absolute;
          top: 10px;
          right: 0;
        }

        > li {
          display: inline;
          color: $szurke;
          font-size: 25px;
          border-right: 1px solid $szurke;
          padding-left: 6px;
          padding-right: 4px;
          &:last-of-type {
            border-right: none;
          }

          @media (min-width: $screen-md-min) {
            font-size: 16px;
          }

          > a {
            color: $szurke;
            text-decoration: none;

            &:hover, &.active {
              color: $kek;
            }
          }
        }
      }

      .navbar-nav {

        > li {
          @media (max-width: $screen-sm-max) {
            border-top: 1px solid $szurke;
          }
          &:last-child {
            a {
              @media (min-width: $screen-md-min) {
                margin-right: -15px;
              }
            }
          }

          > a {
            padding-left: 15px;
            padding-right: 15px;
            background-color: transparent;
            color: $sotetszurke;
            font-size: 20px;

            @media (min-width: $screen-md-min) {
              padding-top: 50px;
              padding-bottom: 10px;
              font-size: 18px;
            }

            &:hover, &:focus {
              background-color: transparent;
              color: $kek;
            }
          }
        }

        .active {
          color: $kek;
          border-bottom: 6px solid $kek;
        }

      }
    }
  }
}
