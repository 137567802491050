footer {
  .vilagos {
    background: #5a5a5a url("../../images/vilagos_pattern.jpg") repeat 0% 0%;
    color: #fff;
    padding-bottom: 45px;
    #footer-cim {
      @media (min-width: $screen-sm-min) {
        margin-left: -15px;
      }
      font-weight: bold;
      margin-bottom: 40px;
    }

    h2 {
      color: #fff;
      font-size: 45px;
    }

    .sav {

      &:first-child {
        @media (min-width: $screen-sm-min) {
          border-right: 2px solid #666;
          padding-left: 0;
          padding-top: 20px;
        }
        padding-bottom: 27px;
      }

      &:last-of-type {
        @media (min-width: $screen-md-min) {
          border-left: 2px solid #666;
          padding-right: 0;
        }
      }

      #industria-footer-logo {
        max-width: 170px;
      }

      #euro-holges-logo {
        max-width: 80px;
      }

      h2 {
        font-size: 20px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        font-weight: 200;
        line-height: 1.2;
      }

      #footer-form {

        label {
          font-size: 13px;
          color: #aaa;
        }

        input, textarea {
          background-color: #666666;
          border-color: #666666;
          color: white;
          &:focus {
            border-color: $kek;
            box-shadow: none;
          }
        }
      }
    }

    .footer-industria {
      h2 {
        margin-top: 40px;
        margin-bottom: 45px;
      }
    }
    .footer-euro {
      h2 {
        margin-top: 25px;
        margin-bottom: 45px;
      }
    }
  }

  .sotet {
    background: #5a5a5a url("../../images/sotet_pattern.jpg") repeat 0% 0%;
    div {
      padding: 20px 0 15px;
      color: #767676;
      font-size: 14px;
    }
  }

  .btn-primary{
    margin-bottom: 40px;
  }
}