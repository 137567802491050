#fooldal {
  background: $bezs url("../../images/bg_pattern.jpg") repeat 0% 0%;
  padding-bottom: 60px;
  > .container-fluid {
    padding: 0;
    position: relative;
    > #fokep {
      max-width: 100%;
      border-bottom: 6px solid $sotetszurke;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    #fokep-szoveg {
      text-align: center;
      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: 7vw;
        text-align: left;
      }
      h2{
        font-size: 35px;
        //text-shadow: -1px 0 1px white, 0 1px 1px white, 1px 0  1px white, 0 -1px  1px white;

        @media (min-width: $screen-md-min) {
          font-size: 40px;
        }
        @media (min-width: $screen-lg-min) {
          font-size: 60px;
        }
        color: $sotetszurke;
        //color: white;
        @media (max-width: $screen-sm-max){
          color: $sotetszurke;
        }
        text-transform: uppercase;
        line-height: 1.2;

        span{
          font-size: 35px;
          @media (min-width: $screen-md-min) {
            font-size: 40px;
          }
          @media (min-width: $screen-lg-min) {
            font-size: 60px;
          }
          color: $kek;
          //color: white;
          @media (max-width: $screen-sm-max){
            color: $kek;
          }

        }
      }
    }
  }

  > .container {
    .felso-sav {
      @media (max-width: $screen-xs-max) {
        margin-top: 100px;
      }
      position: relative;
      margin-bottom: 50px;
      background: $bezs url("../../images/csik-hatter.png") repeat-x 0% 100%;
      svg {
        width: 120px;
        height: 140px;
        position: absolute;
        top: -74px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        .cls-1 {
          fill: $kek;
        }
      }
      .felso-elemek {
        border-top: 6px solid $kek;
        margin-top: -6px;
        padding-top: 95px;

        >div{
          min-height: 212px;
        }

        @media (max-width: $screen-xs-max) {
          margin-bottom: 40px;
        }


        h2 {
          font-size: 18px;
          @media (min-width: $screen-md-min) {
            font-size: 25px;
          }
          text-transform: uppercase;
          color: $kek;
          margin-top: 0;
          height: $line-height-computed * 3.1;
          margin-bottom: 20px;
          vertical-align: middle;
        }
        p {
          font-weight: 200;
          font-size: 16px;
          height: $line-height-computed * 5;
          margin-bottom: 0;
          @media (max-width: $screen-xs-max) {
            height: $line-height-computed * 3;
          }
        }
        a {
          margin-bottom: 74px;
          @media (max-width: $screen-xs-max) {
            margin-bottom: 150px;
          }
        }
      }
    }

    .kozepso-sav {

      .col-xs-12{
        h2 {
          font-size: 35px;
          @media (min-width: $screen-sm-min) {
            font-size: 45px;
          }
          margin-bottom: 60px;
        }
      }

      .bal-oszlop {

        p {
          font-size: 18px;
          font-weight: 200;
          margin-bottom: 50px;
        }
      }

      .jobb-oszlop {
        .szolgaltatasok-lista {
          list-style: none;
          @media (max-width: $screen-xs-max) {
            padding-left: 10px;
          }
          > li >a{
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 40px;

            svg {
              width: 90px;
              min-width: 90px;
              margin-right: 20px;
              path, rect {
                fill: $kek;
              }
            }

            p {
              padding-top: 5px;
              font-size: 15px;
              @media (min-width: $screen-md-min) {
                font-size: 20px;
              }
              font-weight: bold;
              color: $kek;
              text-transform: uppercase;
              //vertical-align: middle;
              line-height: 1;
            }

            &:hover{
              p{
                color: $text-color;
              }
            }
          }
        }
      }
    }
  }
}