@import "_bootstrap.scss";
@import "_header";
@import "_footer";
@import "_fooldal";
@import "_szoveg";
@import "_szolgaltatas";
@import "_galeria";
@import "_fancybox";
@import "_kapcsolat";

h1 {
  color: $kek;
}

h2 {
  color: $kek;
  font-weight: bold;
}

.btn-primary {
  background-color: $kek;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  .glyphicon {
    top: 3px;
    padding-left: 3px;
  }
}

#map{
  width: 100%;
  height: 533px;
}