#kapcsolat {
  background: $bezs url("../../images/bg_pattern.jpg") repeat 0% 0%;
  padding-bottom: 20px;
  .container {
    /*@media (min-width: $screen-sm-min) {
      padding: 0 100px 0 115px;
    }*/

    .row-felso {
      background: $bezs url("../../images/csik-hatter.png") repeat-x 0% 100%;
      margin-bottom: 60px;
      padding-bottom: 50px;
      .cim {
        @media (min-width: $screen-sm-min) {
          padding-left: 0;
        }
        > h1 {
          margin-top: 60px;
          margin-bottom: 60px;
          font-weight: bold;
          font-size: 45px;
        }
      }

      .szoveg {
        @media (min-width: $screen-sm-min) {
          padding-left: 0;
        }
        font-weight: bold;
        font-style: italic;
        font-size: 22px;
        margin-bottom: 80px;
      }
      .kapcsolat-industria, .kapcsolat-euro {
        h2 {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 45px;
          margin-top: 0;
        }
        p{
          font-size: 16px;
        }
      }

      .kapcsolat-industria {
        @media (min-width: $screen-sm-min) {
          padding-left: 0;
        }
        img {
          width: 170px;
          margin-top: 14px;
          margin-bottom: 42px;
        }

      }

      .kapcsolat-euro {
        @media (max-width: $screen-xs-max) {
          margin-top: 50px;
        }
        img {
          width: 78px;
          margin-bottom: 26px;
        }
      }
    }

    .row-also {

      .col-md-offset-1 {
        @media (min-width: $screen-sm-min) {
          padding-left: 0;
        }
      }

      .form-group {
        margin-bottom: 19px;
      }

      h2 {
        margin-bottom: 35px;
        font-size: 20px;
        font-weight: bold;
      }

      label {
        font-size: 13px;
        font-weight: bold;
        color: #aaa;
        margin-bottom: 8px;
      }

      input, textarea {
        background: #ddd;
        color: $kek;
      }

      button {
        margin-top: 20px;
      }
    }
  }
}