$fancybox-dir: "/public/vendor/fancybox/source/";
$fancybox-nav-width: 66px;
$fancybox-close-width: 46px;

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: transparent;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

/*
.fancybox-opened .fancybox-skin {
	-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
	   -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
	        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
*/

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
  max-width: 80vw;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 80vw;
  max-height: 100%;
}

/*
#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
	background-image: url('@{fancybox-dir}fancybox_sprite.png');
}
*/

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('@{fancybox-dir}fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
  position: fixed;
  top: $grid-gutter-width;
  right: $grid-gutter-width * 1.5;
  cursor: pointer;
  z-index: 8040;
  background: url("/images/fancybox-close.png") 50% 50% no-repeat;
  @include square($fancybox-close-width);
  @include opacity(.9);
  @include transition(opacity .2s);

  &:hover {
    @include opacity(1);
    background: url("/images/fancybox-close-hover.png") 50% 50% no-repeat;
  }
}


.fancybox-nav {
  position: absolute;
  top: 0;
  width: $fancybox-nav-width;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('@{fancybox-dir}blank.gif'); /* helps IE */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  z-index: 8040;
}

.fancybox-prev {
  left: -$fancybox-nav-width - $grid-gutter-width;
}

.fancybox-next {
  right: -$fancybox-nav-width - $grid-gutter-width;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  margin-top: -$fancybox-nav-width / 2;
  cursor: pointer;
  z-index: 8040;
  @include square($fancybox-nav-width);
  @include opacity(.8);
  @include transition(opacity .2s);
}

.fancybox-nav:hover span {
  @include opacity(1);

}

.fancybox-prev span {
  left: 0;
  background: url("/images/fancybox-prev.png") 50% 50% no-repeat;
  &:hover{
    background: url("/images/fancybox-prev-hover.png") 50% 50% no-repeat;
  }
}

.fancybox-next span {
  right: 0;
  background: url("/images/fancybox-next.png") 50% 50% no-repeat;
  &:hover{
    background: url("/images/fancybox-next-hover.png") 50% 50% no-repeat;
  }
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  //	background: url('fancybox_overlay.png');
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(55,55,54,0.95) !important;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
  visibility: hidden;
  position: relative;
  text-shadow: none;
  z-index: 8050;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  //@include font-semibold;
  //@include text-center;
  //@include text-uppercase;

  .sorszam {
    color: $text-color;
    margin: 1em 0;
  }
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(55, 55, 54, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, .8);
}

@media (max-width: $grid-float-breakpoint-max) {
  .fancybox-nav {

  }

  .fancybox-prev {
    left: 10px;
  }

  .fancybox-next {
    right: 10px;
  }
}

/*Retina graphics!*/
/*
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url('fancybox_sprite@2x.png');
		background-size: 44px 152px; // The size of the normal image, half the size of the hi-res image
	}

	#fancybox-loading div {
		background-image: url('fancybox_loading@2x.gif');
		background-size: 24px 24px; // The size of the normal image, half the size of the hi-res image
	}
}
*/