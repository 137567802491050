#szoveges {
  background: $bezs url("../../images/bg_pattern.jpg") repeat 0% 0%;

  > .container {
    @media (min-width: $screen-sm-min) {
      padding: 0 115px;
    }

    > h1 {
      font-size: 45px;
      @media (max-width: $screen-xs-max) {
        font-size: 35px;
      }
      font-weight: bold;
      color: $kek;
      margin-top: 60px;
      margin-bottom: 47px;
    }
  }

  .szoveg {

    h3 {
      font-size: 22px;
      @media (max-width: $screen-xs-max) {
        font-size: 19px;
      }
      font-weight: bold;
      font-style: italic;
      color: #666666;
      margin-bottom: 30px;
    }

    h4, h5, h6 {
      margin-bottom: 30px;
      color: $szurke;
      font-weight: bold;
    }

    p {
      margin-bottom: 15px;
      font-size: 16px;
      color: #666666;
    }

    strong, b {
      color: $kek;
    }

    ul, ol {
      list-style-position: inside;
      padding-left: 0;
      li {
        font-weight: bold;
      }
    }

    img {
      max-width: 100%;
      @include img-responsive();
    }

    figcaption{
      color: $szurke;
      font-size: 14px;
    }

    a{

      &:hover{
        color: #555;
      }
    }

    table {
      width: 100%;
      border: 0;
      thead {
        > tr {
          background-color: $kek;
          border: 1px solid $szurke;
          color: white;
          > th {
            text-align: center;
            border: 1px solid $szurke;
            vertical-align: middle;
            padding: 5px 0;
          }
        }
      }

      tbody {
        > tr {
          border: 1px solid $szurke;
          > td {
            border: 1px solid $szurke;
            padding: 2px 0;
            vertical-align: middle;
            color: $kek;
            text-align: center;
          }
        }
        > tr:nth-child(odd) {
          background-color: #ddd;
        }

        > tr:nth-child(even) {
          background-color: #ccc;
        }
      }
    }
  }

}