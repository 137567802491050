#szolgaltatasok {
  background: $bezs url("../../images/bg_pattern.jpg") repeat 0% 0%;
  .container {
    @media (min-width: $screen-sm-min) {
      padding: 0 115px;
    }
    h1 {
      margin-top: 60px;
      font-size: 45px;
      font-weight: bold;
      margin-bottom: 45px;
      @media (max-width: $screen-xs-max) {
        font-size: 35px;
        margin-bottom: 30px;
      }
    }

    .szoveg {
      font-weight: bold;
      font-style: italic;
      font-size: 22px;
      margin-bottom: 80px;
    }

    .szolgaltatasok-lista {
      list-style: none;
      padding: 0;
      > li > a {
        text-decoration: none;
        align-items: center;
        @media (min-width: $screen-sm-min) {
          display: flex;
          align-items: flex-start;
        }
        justify-content: flex-start;
        margin-bottom: 35px;
        svg {
          min-width: 90px;
          width: 90px;
          margin-right: 25px;
          path, rect {
            fill: $kek;
          }
        }
        div {
          padding-top: 35px;
        }

        h2 {
          @media (min-width: $screen-sm-min) {
            margin-top: 0px;
          }
          margin-top: 30px;
          font-size: 20px;
          text-transform: uppercase;
        }

        p {
          font-size: 16px;
          line-height: 1.3;
        }

        &:hover {
          h2 {
            color: $text-color;
          }
        }
      }
    }
  }
}

#szolgaltatas {
  background: $bezs url("../../images/bg_pattern.jpg") repeat 0% 0%;
  padding-bottom: 65px;
  .container {
    @media (min-width: $screen-sm-min) {
      padding: 0 115px;
    }
    h1 {
      margin-top: 60px;
      font-size: 45px;
      font-weight: bold;
      margin-bottom: 45px;
      @media (max-width: $screen-xs-max) {
        font-size: 35px;
        margin-bottom: 30px;
      }
    }

    .szoveg {
      margin-bottom: 37px;
      font-size: 16px;
      color: $text-color;
      margin-bottom: 60px;
    }

    .kepek {
      margin-bottom: 20px;
      .kep {
        margin-bottom: 20px;
        > a {
          display: block;
          overflow: hidden;
          height: 100%;
          position: relative;
           .hover-negyzet {
            position: absolute;
            top: 10px;
            left: 10px;
            bottom: 10px;
            right: 10px;
            background: rgba(255, 255, 255, 0.8) url("../../images/nagyito.png") no-repeat 50% 50%;
            opacity: 0;
          }

          &:hover {
            img {
              //.scale(1.05);
              @include scale(1.08);
            }
            .hover-negyzet {
              opacity: 1;
              @include transition(opacity(1s));
            }
          }
        }
      }
    }
  }
}