#galeria {
  background: $bezs url("../../images/bg_pattern.jpg") repeat 0% 0%;
  padding-bottom: 20px;
  .container {
    @media (min-width: $screen-sm-min) {
      padding: 0 100px;
    }

    h1{
      margin-top: 60px;
      margin-bottom: 60px;
      font-weight: bold;
      font-size: 45px;
    }
    .galeria-reszletek {
      $galeria-kep-margin: 10px;
      $grid-unit: 200px;
      //$grid-unit: unit(12px);

      /*
      &.row {
          margin-left: $grid-gutter-width / -2 - $galeria-kep-margin / 2;
          margin-right: $grid-gutter-width / -2 - $galeria-kep-margin / 2;
      }
      */

      .set-grid-size,
      .galeria-elem {
        //.size($grid-unit, $grid-unit);
        @include size($grid-unit, $grid-unit);
        float: left;
        padding: $galeria-kep-margin ;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          //.transition(transform .2s);
          @include transition(transform .2s);
        }

        > a {
          display: block;
          height: 100%;
          overflow: hidden;
          position: relative;
          >.hover-negyzet{
            position: absolute;
            top: 10px;
            left: 10px;
            bottom: 10px;
            right: 10px;
            background: rgba(255,255,255,0.8) url("../../images/nagyito.png") no-repeat 50% 50%;
            opacity: 0;
          }
        }

        &:hover {
          img {
            //.scale(1.05);
            @include scale(1.05);
          }
          .hover-negyzet{
            opacity: 1;
            @include transition(opacity(1s));
          }

        }
      }

      .kep-2x {
        //.size($grid-unit * 2, $grid-unit * 2);
        @include size($grid-unit * 2, $grid-unit * 2);
      }

    }
    @media (max-width: $screen-xs-max){

      .galeria-reszletek {
        $galeria-kep-margin: 1vw;
        $grid-unit: 51vw;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        .set-grid-size,
        .galeria-elem {
          padding: $galeria-kep-margin ;
          margin-left: auto;
          margin-right: auto;
          right: 0;

          &, .kep-2x {
            @include size($grid-unit, $grid-unit);
          }
        }

      }
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){

      .galeria-reszletek {
        $galeria-kep-margin: 1vw;
        $grid-unit: 37vw;

        .set-grid-size,
        .galeria-elem {
          padding: $galeria-kep-margin ;
          /*margin-left: auto;
          margin-right: auto;
          right: 0;*/

          &, .kep-2x {
            @include size($grid-unit, $grid-unit);
          }
        }

      }
    }
  }
}